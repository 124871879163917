@media (max-width: 1366px) {
  .new-hero-text h2 {
    font-size: 56px;
  }
  .new-hero-text p {
    font-size: 24px;
  }
  .theme-btn {
    font-size: 22px;
  }
  .new-hero-text {
    padding-top: 80px;
  }
  .new-title h2,
  .testimonial-sec h2,
  .contact-content h2 {
    font-size: 50px;
  }
}

@media only screen and (device-width: 1024px) and (device-height: 1366px) {
  .new-hero-sec {
    height: 56vh;
  }
}

@media (max-width: 1024px) {
  .about-title p {
    font-size: 25px;
  }
  .about-sec p,
  .about2-sec .txt p,
  .test-item p {
    font-size: 20px;
  }
  .new-title h2,
  .testimonial-sec h2,
  .contact-content h2 {
    font-size: 45px;
  }
  .benefits-list li {
    font-size: 22px;
  }
  .reg-data-form .reg-field-txt {
    max-width: 70%;
  }
}

@media (max-width: 768px) {
  .new-hero-sec {
    height: 70vh;
  }
  .new-hero-text h2 {
    font-size: 50px;
  }
  .new-hero-text p {
    font-size: 22px;
    max-width: 70%;
  }
  .theme-btn {
    font-size: 20px;
  }
  .about-title {
    padding: 0 0 20px;
  }
  .about-txt {
    padding: 0;
  }
  .about-section {
    padding: 90px 0;
  }
  .benefits-sec {
    background-color: #f4f4f4;
    padding: 90px 0;
  }
  .new-title {
    margin-top: 40px;
  }
  .contact-content {
    padding: 200px 20px 90px;
  }
  .contact-form input {
    height: 60px;
  }
  .contact-form button {
    min-height: 60px;
  }
  .about2-sec img {
    margin-bottom: 40px;
  }
  .about2-sec {
    padding: 90px 0 90px;
  }
  .resgister-data-txt p {
    font-size: 18px;
  }
  .reg-data-form input[type="text"],
  .reg-data-form input[type="email"] {
    width: 100%;
  }
}
@media (max-width: 540px) {
  .new-hero-sec {
    height: 100vh;
  }
  .new-hero-text {
    text-align: center;
  }
  .new-hero-text h2 {
    font-size: 43px;
  }
  .new-hero-text p {
    max-width: 100%;
  }
  .new-hero-text a {
    margin-top: 32px;
  }
  .about-title p {
    font-size: 22px;
  }
  .about-sec p,
  .about2-sec .txt p,
  .test-item p {
    font-size: 18px;
  }
  .new-title {
    text-align: center;
  }
  .new-title::after {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
  }
  .test-item {
    padding: 0;
  }
  .contact-form input {
    margin-bottom: 15px;
  }
  .contact-content img {
    max-width: 70%;
    top: -14%;
  }
  .contact-sec > div {
    padding: 0;
  }
  .contact-content {
    padding: 180px 20px 90px;
    border-radius: 0;
  }
  .footer-text {
    padding: 0;
    line-height: 0.75 !important; /* Ensure the line height is applied even on smaller screens */
  }
  .theme-btn {
    font-size: 18px;
    padding: 15px 45px;
  }
}

@media (max-width: 480px) {
  .logo img {
    max-width: 60%;
  }
  .new-title h2,
  .testimonial-sec h2,
  .contact-content h2 {
    font-size: 40px;
  }
  .benefits-list li {
    font-size: 20px;
  }
  .about-sec p,
  .about2-sec .txt p,
  .test-item p {
    font-size: 16px;
  }
  .contact-sec {
    padding: 160px 0 90px;
  }
  .contact-content {
    padding: 150px 20px 90px;
  }
  .resgister-data-txt p {
    font-size: 16px;
  }
  .reg-form-title {
    font-size: 30px;
  }
  .reg-tag-line {
    font-size: 18px;
  }
  .reg-data-form .reg-field-txt {
    max-width: 100%;
  }
  .confir-footer {
    padding: 100px 0 40px;
  }
  .reg-data-form button {
    font-size: 20px;
  }
}

@media (max-width: 375px) {
  .contact-content {
    padding: 130px 20px 90px;
  }
}
@media (max-width: 360px) {
  .about-title p {
    font-size: 20px;
  }
  .title h2,
  .testimonial-sec h2,
  .contact-content h2 {
    font-size: 37px;
  }
  .reg-data-form img {
    max-width: 200px;
  }
}

@media (max-width: 320px) {
  .new-hero-text h2 {
    font-size: 38px;
  }
  .new-hero-text p {
    font-size: 20px;
  }
  .benefits-list li img {
    margin-right: 16px;
    max-width: 20px;
  }
  .theme-btn {
    font-size: 16px;
    padding: 15px 40px;
  }
  .benefits-list li {
    font-size: 16px;
  }
  .contact-content {
    padding: 110px 20px 90px;
  }
}

@media (max-width: 280px) {
  .new-hero-text h2 {
    font-size: 34px;
  }
  .theme-btn {
    font-size: 15px;
    padding: 15px 30px;
  }
  .new-title h2,
  .testimonial-sec h2,
  .contact-content h2 {
    font-size: 32px;
  }
}
