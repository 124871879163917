@media (min-width: 1300px) {
  .container {
    max-width: 1286px;
  }
  .bankroll .container {
    max-width: 1123px;
  }
  .about-us .container {
    max-width: 1083px;
  }
  .card-wrapper .no-gutters,
  .bankroll .no-gutters {
    margin: 0 -35px;
  }
  .card-wrapper .no-gutters [class*="col-"],
  .bankroll .no-gutters [class*="col-"] {
    padding: 0 25px;
  }
  .news-main .row {
    margin: 0 -22.5px;
  }
  .news-main .row [class*="col-"] {
    padding: 0 22.5px;
  }
}
@media (max-width: 1600px) {
  header .right-links li {
    margin-left: 10px;
  }
  header .right-links {
    margin-left: 5px;
  }
  header .navbar-nav li a {
    padding: 0 10px;
  }
  .hero-text h1 {
    font-size: 56px;
  }
  .hero-text ul li {
    font-size: 16px;
    margin-bottom: 12px;
  }
}
@media (max-width: 1299px) {
  header .navbar-nav li a {
    font-size: 14px;
  }
  header .navbar-nav li a {
    padding: 0 10.5px;
  }
  header .btn {
    font-size: 14px;
  }
  .news-card .card_body h3 {
    font-size: 18px;
  }
  .news-card .card_body p {
    font-size: 14px;
    line-height: 20px;
  }
  .content-about p {
    font-size: 15px;
  }
  .card-wrapper .no-gutters,
  .bankroll .no-gutters {
    margin: 0 -15px;
  }
  .card-wrapper .no-gutters [class*="col-"],
  .bankroll .no-gutters [class*="col-"] {
    padding: 0 15px;
  }
  .card-counter {
    padding: 0 28px;
  }
  .card-counter p {
    font-size: 18px;
    line-height: 1.5;
  }
  .video-academy .content {
    padding: 0;
  }
  .hero-main {
    padding: 62px 0 0 0;
  }
  /*****/
  .related-blog-box .text-box {
    padding-right: 20px;
  }
  .btn {
    min-width: 148px;
    /* font-size: 12px; */
  }
}

@media (max-width: 1199px) {
  .content-about {
    padding: 15px;
  }
  .card-box p {
    font-size: 14px;
    line-height: 20px;
  }
  .card-punto p {
    font-size: 16px;
    line-height: 29px;
  }
  header .right-links li {
    margin-left: 10px;
  }
  header .cta-primary.btn {
    min-width: 160px;
  }
  header .navbar-nav li a {
    font-size: 12px;
    padding: 0 7px;
  }
  header .right-links li.language .drop-link i img {
    height: 8px;
  }
  .hero-main {
    padding: 62px 0 0 0;
  }
  .hero-text h1 {
    font-size: 40px;
    line-height: 55px;
    max-width: 440px;
  }
  .hero-text p {
    font-size: 18px;
  }
  .hero-text .btn {
    min-width: 255px;
    height: 50px;
    font-size: 18px;
  }
  .bankroll {
    padding: 70px 0;
  }
  .bankroll .cta-secondary {
    border-radius: 5px;
    margin-top: 50px;
  }
  .video-academy {
    padding: 70px 0;
  }
  .video-academy h2 {
    font-size: 34px;
  }
  .speciality {
    padding: 70px 0;
  }
  .speciality .content,
  .card-game {
    margin-top: 50px;
  }
  .speciality .content {
    font-size: 18px;
  }
  .players-info {
    padding: 70px 0;
  }
  .card-counter {
    padding: 0 0;
  }
  .about-us {
    padding: 70px 0;
  }
  .content-about {
    margin-top: 50px;
  }
  .contact {
    padding: 70px 0;
  }
  .news-main h2 {
    margin-bottom: 0;
  }
  .news-main .news-card {
    margin-top: 50px;
  }
  .breadcrumb-banner {
    min-height: 350px;
  }
  .chat-text p {
    font-size: 18px;
    line-height: 32px;
  }
  .chat-text .btn {
    min-width: 198px;
    font-size: 18px;
  }
  .contact-main form .btn {
    min-width: 97px;
    font-size: 18px;
  }

  .heading {
    font-size: 32px;
    line-height: 1;
  }
  .card-coach h4 a {
    font-size: 18px;
  }
  .spin {
    padding: 40px 0;
  }
  header .right-links {
    margin-left: 0px;
  }

  header .navbar-nav li .dropdown-menu {
    margin-top: 11px;
  }
  /******/
  .related-blog-box .text-box {
    padding: 10px;
  }
  .related-blog-box .text-box h4 {
    font-size: 12px;
    margin: 0 0 20px 0;
  }
  .blog-inner .text-box p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .blog-sec .title h3 {
    font-size: 30px;
  }
  header .btn.enter {
    min-width: 148px !important;
    font-size: 12px !important;
  }

  .hero-text ul li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .hero-text ul {
    margin: 20px 0;
  }
  header .right-links li .cta-dark {
    min-width: 70px;
  }
  header .btn {
    font-size: 12px;
  }
  header .cta-primary.btn {
    min-width: 140px;
  }
}
@media only screen and (device-width: 1024px) and (device-height: 1366px) {
  .hero-main {
    height: 45vh;
  }
  .hero-text ul {
    margin: 20px 0;
    max-width: 40%;
  }
}
@media (max-width: 1024px) {
  .hero-text ul {
    max-width: 40%;
  }
}
@media (max-width: 991px) {
  header .right-links {
    margin: 0;
    margin-left: auto;
  }
  header .brand-holder {
    order: 1;
  }
  .menu-holder {
    order: 3;
    margin-left: 21px;
  }
  .menu-holder .navbar-toggler {
    padding: 0;
    margin: 0;
  }
  header .navbar-collapse {
    order: 4;
    /*background-color: #bd1818;*/
    background-color: #b23130;
  }
  .right-links {
    order: 2;
    margin-left: auto;
  }
  header .navbar-nav li {
    margin: 0;
    line-height: 1;
  }
  header .navbar-nav li .dropdown-menu li a,
  header .navbar-nav li a {
    font-size: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
  header .navbar-nav li.right-links {
    margin: 0 10px;
    line-height: 1;
    flex-direction: column;
    align-items: flex-start;
  }
  header .navbar-nav li a.drop-link {
    border: none;
    padding-left: 0;
  }
  header .navbar-nav li a.drop-link span.icon-flag {
    width: 24px;
    height: 24px;
  }
  header .navbar-nav li a.drop-link i {
    margin-left: 10px;
  }
  header .navbar-nav li.right-links .dropdown-menu button {
    display: block;
    width: 100%;
    background-color: transparent;
    text-align: left;
    border: none;
    border-radius: 0;
    padding: 8px 0;
  }
  header .navbar-nav li.right-links .dropdown-menu button span.icon-flag {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  header .navbar-nav li.right-links .dropdown-menu button span.icon-flag img {
    width: 24px;
    height: 24px;
  }
  header .navbar-nav li.right-links .dropdown-menu button small {
    margin-left: 10px;
    color: #fff;
    font-size: 16px;
    flex: 1;
  }
  header .navbar-nav {
    padding-bottom: 8px;
  }
  .bankroll .card-box {
    margin-top: 27px;
  }
  .bankroll .cta-secondary {
    margin-top: 36px;
  }
  .video-academy .content {
    text-align: center;
    margin-bottom: 18px;
  }
  .video-holder {
    text-align: center;
  }
  .video-holder .btn {
    margin-top: 30px;
  }
  .hero-main {
    padding: 62px 0 0 0;
  }
  .footer-links li a {
    font-size: 14px;
  }
  .footer-links li {
    margin: 0 12px;
  }
  .social-links {
    margin-top: 20px;
  }
  .copyrights {
    padding-top: 15px;
    margin: 20px 0 0;
  }
  footer {
    padding: 20px 0;
  }
  .breadcrumb-banner {
    min-height: 300px;
  }
  .chat-text {
    margin-bottom: 30px;
  }
  .contact-main .caption {
    margin-bottom: 20px;
  }
  .news-wrapper {
    padding: 50px 0;
  }
  .live-video {
    padding: 25px 0;
  }
  .direct-stream {
    padding: 25px 0 50px;
  }
  .invite-friend {
    padding: 44px 0 25px;
  }
  .coaching-staking .form-card .button-holder .btn {
    min-width: 131px;
  }
  .leadtext {
    font-size: 18px;
    line-height: 28px;
  }
  .coaching-staking {
    padding: 70px 0 45px;
  }
  .coching-list {
    justify-content: center;
  }
  .coching-list li {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
  }
  .request-info {
    padding: 45px 0;
  }
  .navbar-brand img {
    max-width: inherit;
  }
  header .navbar-nav li .dropdown-menu {
    margin: 0;
    position: relative;
  }
  header .navbar-nav li .toggle_link {
    display: flex;
  }
  header .navbar-nav li .toggle_link span {
    flex: 1;
  }
  header .navbar-nav li .dropdown-menu li a {
    padding-left: 30px;
    font-size: 14px;
  }
  .right-links .language {
    order: 1;
    margin-right: 10px;
  }
  .right-links li:nth-child(2) {
    order: 2;
  }
  header .right-links li.language .dropdown-menu {
    border-radius: 0;
    min-width: min-content;
  }
  header .right-links li.language .dropdown-menu button small {
    font-size: 14px;
  }
  header .right-links li.language .dropdown-menu .icon-flag,
  header .right-links li.language .drop-link .icon-flag {
    width: 25px;
    height: 25px;
  }
  /****/
  .blog-inner .text-box p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .blog-sec .title h3 {
    font-size: 22px;
  }
  .blog-inner {
    margin: 30px 0 0 0;
  }
  .related-blog-box .text-box ul li {
    font-size: 12px;
  }
  .blog-inner .social-media {
    margin: 50px 0 0 0;
  }
  header .navbar-nav li .dropdown-menu {
    background-color: transparent;
  }
  .hero-text h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .hero-text ul li {
    font-size: 15px;
  }
  .hero-text .btn {
    height: 45px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .hero-main {
    height: 60vh;
  }
  .hero-text ul {
    max-width: 40%;
  }
}
@media (max-width: 767px) {
  .card-wrapper .no-gutters [class*="col-"],
  .bankroll .no-gutters [class*="col-"] {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .card-game p {
    font-size: 16px;
  }
  .card-game img {
    margin-bottom: 4px;
    height: 64px;
  }
  .speciality .content {
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
  .card-counter {
    margin-top: 30px;
  }
  .content-about {
    margin-top: 30px;
  }
  .contact .form-row {
    flex-direction: column;
  }
  .contact form .form-group {
    margin-bottom: 29px;
  }
  .contact form .btn {
    min-width: 100%;
  }
  .copyrights ul {
    justify-content: center;
    align-items: center;
  }
  .copyrights p {
    text-align: center;
  }
  .breadcrumb-banner {
    min-height: 250px;
  }
  .breadcrumb-banner p {
    font-size: 18px;
  }
  .breadcrumb-banner h1 {
    font-size: 36px;
  }
  .contact-main {
    padding: 50px 0;
  }
  .form-card .button-holder .btn {
    min-width: 100%;
  }
  .form-card .form-row {
    flex-direction: column;
  }
  .form-card .form-row .form-group {
    margin: 0 0 21px;
  }
  .leadtext {
    font-size: 16px;
    line-height: 28px;
  }
  .coaching-staking .form-card .button-holder .btn {
    min-width: 100%;
  }
  .coching-list li {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
  .spin-card-wrapper {
    max-width: 510px;
  }
  .spin-card-wrapper .spin-card:nth-child(2) {
    margin-top: 32px;
  }
  .punto-main .card-punto {
    padding: 0;
    margin-bottom: 30px;
  }
  .punto-main {
    padding: 15px 0 0;
  }
  /*******/
  .related-blog-box .text-box h4 {
    font-size: 14px;
    margin: 0 0 26px 0;
  }
  .related-blog-box .text-box ul li {
    font-size: 14px;
  }
  .related-blog {
    margin-top: 50px;
  }
  .hero-main {
    padding: 62px 0 0 0;
    height: 100vh;
  }
  .hero-text h1 {
    max-width: 390px;
  }
  .hero-text ul {
    margin: 10px 0;
  }
  .hero-text ul li {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .page-title .caption-title, .page-title.refer-friend .caption-title{
    padding: 30px 0 0 0;
  }
}

@media (max-width: 575px) {
  h2 {
    font-size: 22px;
  }
  .hero-text h1 {
    font-size: 28px;
    line-height: 35px;
    position: relative;
  }
  .hero-text h1:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -10px;
    width: 70px;
    height: 2px;
    background-color: #ed1307;
  }
  .hero-text P {
    font-size: 12px;
    color: #fff;
    line-height: 18px;
  }
  .hero-text .btn {
    min-width: inherit;
    font-size: 14px;
    width: 100%;
    max-width: 190px;
  }
  .hero-main {
    background-image: url(../images/mobail-banner-img.png);
    background-position: revert;
    padding: 62px 0 0 0;
    align-items: flex-end;
    background-size: cover;
    background-position: center -30px;
  }
  .hero-text {
    padding-bottom: 30px;
  }
  .video-academy h2 {
    font-size: 22px;
  }
  .video-holder .btn {
    margin-top: 20px;
  }
  .video-academy {
    padding: 50px 0;
  }
  .video-academy .btn {
    min-width: 150px;
    height: 50px;
  }
  .title h2 {
    font-size: 22px;
  }
  .card-game {
    margin-top: 24px;
  }
  .card-wrapper .no-gutters {
    margin: 0 -6px;
  }
  .card-wrapper .no-gutters [class*="col-"] {
    padding: 0 6px;
  }
  .card-game img {
    max-width: 20%;
    height: auto;
    width: 100%;
  }
  .card-game {
    padding: 10px;
  }
  .card-game p {
    font-size: 10px;
  }
  .speciality .content {
    font-size: 14px;
    line-height: 21px;
    margin-top: 44px;
  }
  .speciality {
    padding: 50px 0;
  }
  .players-info {
    padding: 20px 0 50px;
  }
  .card-counter .card-icon {
    width: 140px;
    height: 140px;
  }
  .card-counter .card-icon img {
    height: 62px;
  }
  .about-us {
    padding: 50px 0;
  }
  .content-about {
    padding: 14px 30px;
  }
  .contact {
    padding: 50px 0;
  }
  .contact form {
    margin: 40px auto 0;
  }
  .news-main {
    display: none;
  }
  .footer-links {
    flex-direction: column;
  }
  .footer-links li {
    margin: 0;
    margin-bottom: 10px;
  }
  .footer-links li a {
    display: inline-flex;
    line-height: 25px;
  }
  .copyrights {
    margin: 0;
    border: none;
  }
  .faqs_title .media-body h4 {
    font-size: 14px;
    line-height: 17px;
  }
  .faqs_title .media-body p {
    font-size: 13px;
  }
  .faqs_title {
    padding: 12px;
  }
  .faqs-main .card .card-body {
    padding: 0 12px 12px;
    font-size: 13px;
  }
  .faqs-main {
    padding: 50px 0 20px;
  }
  .breadcrumb-banner {
    min-height: 160px;
    text-align: left;
  }
  .breadcrumb-banner h1 {
    font-size: 30px;
    line-height: 36px;
    margin: 0;
  }
  .breadcrumb-banner p {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }
  .faqs-main .card {
    margin-top: 11px;
  }
  .faqs-card {
    margin-bottom: 30px;
  }
  .contact-main {
    padding: 15px 0 50px;
  }
  .chat-text p {
    font-size: 16px;
  }
  .chat-text .btn {
    min-width: 267px;
    font-size: 23px;
  }
  .caption p {
    font-size: 16px;
    line-height: 25px;
  }
  .pagination .page-link {
    font-size: 12px;
    padding: 0px 8px;
  }
  .pagination li {
    line-height: 19px;
  }
  .news-wrapper .news-card {
    margin-bottom: 25px;
  }
  .news-wrapper .row {
    margin: 0 -6px;
  }
  .news-wrapper [class*="col-"] {
    padding: 0 6px;
  }
  .heading {
    font-size: 22px;
    line-height: 1;
  }
  .invite-friend .form-card {
    padding: 25px;
  }
  .coching-list li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .our-coaches {
    padding: 0px 0 35px;
  }
  .coaching-staking .leadtext {
    font-size: 14px;
    line-height: 28px;
    margin: 0 auto 30px;
  }
  .coaching-staking {
    padding: 30px 0 45px;
    margin: 0 auto 45px;
  }
  .breadcrumb {
    justify-content: flex-start;
  }
  .breadcrumb-item + .breadcrumb-item::before,
  .breadcrumb-item.active,
  .breadcrumb li a {
    font-size: 16px;
  }
  .punto-main .card-punto {
    max-width: 313px;
  }
  .request-info {
    padding: 20px 0 80px;
  }
  /********/
  .blog-sec .title h3 {
    font-size: 24px;
    line-height: 35px;
    margin: 0 0 10px 0;
  }
  .blog-sec {
    padding: 15px 0;
  }
  .blog-sec .title ul li {
    font-size: 14px;
  }
  .blog-sec .title ul li:first-child {
    width: 100%;
  }
  .blog-inner .text-box p {
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 38px;
  }
  .blog-inner {
    margin: 23px 0 0 0;
  }
  .blog-inner .social-media h4 {
    font-size: 20px;
    margin: 0 14px 0 0;
    padding: 0;
  }
  .blog-inner .social-media ul li {
    padding: 0 15px;
  }
  .blog-inner .social-media ul {
    margin: 0 -15px;
  }
  header .right-links li.language .dropdown-menu .icon-flag,
  header .right-links li.language .drop-link .icon-flag {
    width: 18px;
    height: 18px;
  }
  header .right-links li.language .dropdown-menu .icon-flag,
  header .right-links li.language .drop-link .icon-flag {
    margin-right: 5px;
  }
  .right-links .language {
    margin-right: 4px;
  }
  header .right-links li {
    margin-left: 0px;
  }
  .menu-holder {
    margin-left: 5px;
  }
  header .right-links li.language .drop-link {
    font-size: 14px;
  }
  .hero-text h1 span {
    font-weight: 400;
    display: block;
  }
  .hero-text ul li img {
    margin-right: 10px;
    height: 18px;
  }
  .hero-text ul {
    margin: 20px 0 10px;
  }
  .hero-text ul {
    max-width: 100%;
  }
  .hero-text ul li {
    font-size: 13px;
    margin-bottom: 3px;
  }
}

@media (max-width: 470px) {
  .navbar-brand img {
    height: 40px;
  }
  header .right-links li .btn {
    height: 26px;
    font-size: 12px;
  }
  header .right-links li .btn.cta-dark {
    min-width: 65px;
  }
  header {
    padding: 7px 0;
    min-height: 45px;
  }
  header .navbar-nav {
    margin-top: 10px;
  }
  .bankroll {
    padding: 23px 0 50px;
  }

  .card-punto .title-img,
  .card-box .title-img {
    margin-bottom: 11px;
    height: 86px;
  }
  .card-box p {
    font-size: 12px;
    line-height: 29px;
  }
  .bankroll .cta-secondary {
    min-width: 100%;
  }
  .news-card .card_body h3 {
    font-size: 12px;
    line-height: 15px;
  }
  .news-card .card_body .date-view li {
    font-size: 8px;
    margin: 0 5px;
  }
  .news-card .card_body p {
    font-size: 10px;
    line-height: 19px;
    margin-bottom: 0;
  }
  .news-card .card_body a.btn {
    min-width: 55px;
    height: 18px;
    font-size: 9px;
  }
  .news-card .title-img {
    height: 88px;
  }
  .card-coach h4 {
    line-height: 1;
    margin: 0;
  }
  .card-coach h4 a {
    font-size: 15px;
  }
  .card-coach p {
    font-size: 9px;
    line-height: 14px;
  }
  .card-coach .img-coach {
    margin: 4px;
  }
  .breadcrumb-item + .breadcrumb-item::before,
  .breadcrumb-item.active,
  .breadcrumb li a {
    font-size: 12px;
  }
  .faqs-main {
    padding: 24px 0 20px;
  }
  header .right-links li.language .dropdown-menu {
    margin: 6px 0 0;
  }

  .hero-main {
    padding: 54px 0 0 0;
    background-position: center -20px;
  }

  .hero-text h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .hero-text h1:after {
    bottom: -3px;
  }
  .hero-text ul {
    margin: 10px 0;
  }
  .hero-text ul li {
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 20px;
  }
  .hero-text .btn {
    height: 40px;
    font-size: 12px;
    max-width: 170px;
  }
}
@media (max-width: 474px) {
  .blog-sec .title h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .blog-inner .text-box p {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .blog-inner .social-media ul li {
    padding: 0 5px;
  }
  .blog-inner .social-media ul {
    margin: 0 -5px;
  }
  .related-blog-box .text-box h4 {
    margin: 0 0 15px 0;
  }
  .hero-text h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .hero-text ul li {
    font-size: 15px;
    line-height: 30px;
  }
}
@media (max-width: 414px) and (max-height: 900px) {
  .hero-main {
    align-items: center;
  }
  .hero-main {
    padding: 70% 0 0 0;
    background-position: center -20px;
  }
}
@media (max-width: 414px) and (max-height: 750px) {
  .hero-main {
    padding: 82% 0 0 0;
  }
}
@media (max-width: 375px) {
  .hero-text h1 {
    font-size: 27px;
    line-height: 36px;
  }
  .hero-text ul {
    margin: 20px 0;
  }
  .hero-text .btn {
    height: 45px;
    font-size: 14px;
    max-width: 190px;
  }
  .hero-text ul li {
    font-size: 14px;
    line-height: 28px;
  }
}
@media (max-width: 360px) {
  .hero-text h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .hero-text h1:after {
    bottom: -3px;
  }
  .hero-text ul {
    margin: 10px 0;
  }
  .hero-text ul li {
    font-size: 13px;
    margin-bottom: 8px;
    line-height: 24px;
  }
  .hero-text .btn {
    height: 40px;
    font-size: 12px;
    max-width: 170px;
  }
}

@media (max-width: 320px) {
  .hero-text h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .hero-text ul li {
    font-size: 12px;
    margin-bottom: 6px;
    line-height: 20px;
  }
}

@media (max-width: 280px) {
  .hero-main {
    padding: 90% 0 0 0;
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .collapse.navbar-collapse a.btn.cta-dark {
    display: none;
  }
  .collapse.navbar-collapse .language {
    display: none;
  }
}

@media (min-width: 1200px) {
  .live-video {
    margin-top: 50px;
  }
}

@media only (max-device-width: 1024px) {
  .hero-text {
    width: 40.5%;
    float: left;
  }
  .hero-text h1 {
    font-size: 34px;
    line-height: 37px;
    max-width: 407px;
  }
  .hero-main {
    height: 66vh;
  }
}
