/* INDEX CSS */

/* 
HOME CSS
1. LIBRARIES 
2. RESET 
3.Header 
4. HERO SECTION
5. Bankroll
6. Video Academy
7. Speciality
8. players info
9. About US 
10. Contact 
10. News 
*/


/* 1. LIBRARIES CSS*/

@import "bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

/* 2. RESET CSS */

@font-face {
  font-family: "SFProDisplay-Medium";
  src: url("../webfonts/SFProDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../webfonts/SFProDisplay-Medium.otf") format("opentype"),
    url("../webfonts/SFProDisplay-Medium.woff") format("woff"),
    url("../webfonts/SFProDisplay-Medium.ttf") format("truetype"),
    url("../webfonts/SFProDisplay-Medium.svg#SFProDisplay-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 29px;
  height: 100%;
  color: #343434;
}

a.active,
a:focus,
button:focus,
button.active {
  outline: none;
}

a {
  text-decoration: none;
}

a:focus,
a:hover,
a:active {
  outline: 0;
  box-shadow: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

a:hover {
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  list-style: none;
}

h1 {
  font-weight: 700;
  font-size: 50px;
}

h2 {
  font-weight: 600;
  font-size: 40px;
  color: #000;
}

h3 {
  font-size: 22px;
}

h4 {
  font-weight: 700;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.39;
  letter-spacing: 1.8px;
}

h5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.79;
  letter-spacing: 1.4px;
}

p {
  font-size: 16px;
  line-height: 29px;
  color: #343434;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.form-control:focus {
  box-shadow: none;
}

img {
  max-width: 100%;
}

.btn {
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 7px 10px;
  text-align: center;
  height: 50px;
  min-width: 178px;
}

.btn:focus {
  box-shadow: none;
}

.cta-primary {
  background: #5cb85c;
  color: #fff;
}

.cta-primary:hover {
  background-color: #428e42;
}

.cta-primary:hover {
  color: #fff;
}

.cta-dark {
  background: #070707;
  color: #fff;
}

.cta-dark:hover {
  color: #fff;
  background: #3a3838;
}

.cta-secondary {
  background-color: #b23130;
  color: #fff;
}

.cta-secondary:hover {
  color: #fff;
  background-color: #98201f;
}

.heading2 {
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #000;
  margin: 0;
}

/* 3. Header CSS */

header {
  background-color: #b23130;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 15px;
  z-index: 999;
}

header .navbar-nav li a {
  font-size: 16px;
  color: #ffebeb;
  padding: 0 13.5px;
}

header .navbar-nav li a:hover {
  opacity: 0.8;
}

header .navbar-nav li .dropdown-menu {
  border-radius: 0;
  padding: 0 !important;
  background-color: #b23130;
  box-shadow: none;
  margin-top: 15px;
  border: none;
}

header .navbar-nav li .dropdown-menu li {
  padding: 0;
  margin: 0;
}

header .navbar-nav li .dropdown-menu li {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

header .navbar-nav li .dropdown-menu li a {
  padding: 3px 8px;
  display: block;
}

header .navbar-nav li .toggle_link i {
  margin-left: 5px;
}

header .navbar-nav li .toggle_link i img {
  height: 8px;
}

header .navbar-nav li .toggle_link[aria-expanded="true"] i img {
  transform: rotate(180deg);
}

.navbar-brand {
  margin: 0;
  padding: 0;
}

header .right-links {
  display: flex;
  align-items: center;
  margin-left: 18.5px;
}

header .right-links li {
  margin-left: 22px;
}

header .right-links li .btn {
  height: 40px;
}

header .right-links li .cta-dark {
  min-width: 91px;
}

header .right-links li.language .drop-link {
  display: flex;
  align-items: center;
}

header .right-links li.language .drop-link i {
  margin-left: 9px;
}

header .right-links li.language .dropdown-menu .icon-flag,
header .right-links li.language .drop-link .icon-flag {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-flex;
}

header .right-links li.language .drop-link .icon-flag img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: none;
}

header .right-links li.language .dropdown-menu .icon-flag img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: none;
}

header .right-links li.language .dropdown-menu {
  padding: 0;
  margin: 15px 0 0;
  background-color: #b23130;
}

header .right-links li.language .dropdown-menu button {
  padding: 8px 15px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
}
header .right-links li.language .dropdown-menu button span.icon-flag {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
header .right-links li.language .dropdown-menu button:hover {
  opacity: 0.8;
}

header .right-links li.language .dropdown-menu button small {
  margin-left: 10px;
  color: #fff;
  font-size: 16px;
  flex: 1;
}

/* 4. HERO SECTION */

.hero-main {
  background-image: url(../images/banner-img.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  background-size: cover;
  padding: 62px 15px 0 15px;
  height: 100vh;
  display: flex;
  align-items: center;
}

.hero-text {
  text-align: left;
  max-width: 100%;
  margin: 0 auto;
}

.hero-text h1 {
  line-height: 71px;
  color: #fff;
  line-height: 1.3;
  max-width: 740px;
  width: 100%;
  font-size: 70px;
}
.hero-text ul {
  margin: 33px 0 45px;
}
.hero-text ul li {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.hero-text ul li img {
  margin-right: 19px;
}
.hero-text ul li:last-child {
  margin-bottom: 0;
}
.hero-text p {
  color: #fff;
  font-size: 20px;
  color: #fff;
  margin-bottom: 22px;
}

.hero-text .btn {
  min-width: 252px;
  height: 56px;
  border-radius: 50px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 18px;
  background-color: #bd1818;
  color: #fff;
}

/* 5. Bankroll */

.intro-text {
  font-size: 1.5rem;
  margin-bottom: 100px;
  line-height: 1.5;
  text-align: center;
}

.bankroll {
  padding: 124px 0 89px;
}

.card-punto,
.card-box {
  text-align: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
  padding: 25px 14px;
  height: 100%;
}

.card-punto {
  box-shadow: none;
}

.card-punto h3,
.card-box h3 {
  font-family: "SFProDisplay-Medium";
  margin-bottom: 18px;
}

.card-punto .title-img,
.card-box .title-img {
  margin-bottom: 11px;
  width: 30%;
}

.card-punto p,
.card-box p {
  margin: 0;
}

.bankroll .cta-secondary {
  min-width: 331px;
  height: 50px;
  border-radius: 5px;
  margin-top: 89px;
}

/* 6. Video Academy */

.video-holder {
  max-width: 800px;
  margin: auto;
}

.video-academy {
  padding: 110px 0;
  background: #fff0f1;
}

.video-academy .content {
  padding: 25px;
}

.video-academy h2 {
  margin-bottom: 13px;
}

.video-academy .btn {
  min-width: 178px;
}

/* 7. Speciality */

.speciality {
  padding: 100px 0;
}

.speciality .title {
  text-align: center;
}

.speciality .title h2 {
  margin: 0;
}

.card-game {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  padding: 19px 15px 35px;
  text-align: center;
  margin-top: 89px;
}

.card-game img {
  margin-bottom: 26px;
  width: 50%;
}

.card-game h3 {
  color: black;
}

.card-game p {
  margin: 0;
  font-size: 16px;
  color: #000;
}

.card-game .btn {
  margin-top: 10px;
}

.card-wrapper {
  max-width: 797px;
  margin: 0 auto;
}

.speciality .content {
  text-align: center;
  font-size: 20px;
  text-align: center;
  color: #343434;
  margin: auto;
  margin-top: 60px;
  max-width: 1015px;
  line-height: 40px;
}

/* 8. players info */

.players-info {
  padding: 134px 0 98px;
  background: #fff0f1;
}

.card-counter {
  text-align: center;
}

.card-counter .card-icon {
  width: 169px;
  height: 168px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.card-counter h4 {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #f83647;
  margin: 0;
}

.card-counter p {
  font-size: 22px;
  line-height: 36px;
  color: #343434;
  margin: 0;
}

.card-counter {
  padding: 0 50px;
}

/* Twitch */

div#hispano_poker {
  height: 600px !important;
}

/* 9. About US */

.about-us {
  padding: 90px 0;
}

.about-us h2 {
  text-align: center;
  margin: 0;
}

.content-about {
  text-align: center;
  margin-top: 68px;
  padding: 25px;
  transition: all 0.3s ease-in-out;
}

.content-about:hover {
  border-radius: 8px;
  background: #fff0f1;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
}

.content-about:focus {
  outline: none;
}

.content-about p {
  font-size: 16px;
  line-height: 29px;
  color: #343434;
}

.content-about span {
  font-weight: 500;
  text-decoration: underline;
  color: #b23130;
}

.content-about img {
  margin: auto;
  padding: 20px;
  border-radius: 25px;
  width: 60%;
}

/* 10. Contact  */

.contact {
  padding: 100px 0;
  background-color: #fff0f1;
}

.title {
  max-width: 602px;
  margin: 0 auto;
  text-align: center;
}

.title h2 {
  font-size: 30px;
  margin: 0;
}

.contact form .form-group {
  margin: 0;
}

.contact form .btn {
  min-width: 216px;
}

.contact form .button-holder {
  padding: 0 9px;
}

.contact .form-row {
  margin: 0 -9px;
}

.contact form [class*="col"] {
  padding: 0 9px;
}

.form-control {
  height: 50px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.12);
  border: none;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: #000;
}

.form-control:focus {
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.12);
}

.form-control::placeholder {
  color: #000;
}

textarea.form-control {
  height: auto;
}

.contact form {
  max-width: 850px;
  margin: 73px auto 0;
}

/* 10. News  */

.news-main {
  padding: 76px 0;
}

.news-main h2 {
  margin-bottom: 80px;
}

.news-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  height: calc(100% - 40px);
}

.news-card .title-img {
  height: 200px;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
  object-position: center;
  margin: auto;
  width: 100%;
}

.news-card .card_body {
  padding: 10px 10px 52px 10px;
}

.news-card .card_body h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.news-card .card_body .date-view {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.news-card .card_body .date-view li {
  font-size: 14px;
  color: #b7b7b7;
  margin: 0 10px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
}

.news-card .card_body p {
  font-size: 16px;
  line-height: 25px;
  color: #575757;
  margin: 0 0 18px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.news-card .card_body a.btn {
  min-width: 89.42px;
  height: 30px;
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  bottom: 60px;
}

/* 11. Footer */

footer {
  background: #000;
  padding: 20px 0 60px;
}

.social-links,
.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-links li {
  margin: 0 27.5px;
}

.footer-links li a {
  font-size: 16px;
  color: #fff;
}

.copyrights ul li a:hover,
.footer-links li a:hover {
  color: #98201f;
}

.social-links {
  margin-bottom: 20px;
}

.social-links li a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.social-links li.fb a {
  background: #6362d7;
}

.social-links li.fb a:hover {
  background-color: #29487d;
}

.social-links li.linkdin a {
  background: linear-gradient(#ff9ab6 0%, #f03295 98.95%, #f03295 100%);
}

.social-links li.linkdin a:hover {
  background: linear-gradient(#f03295 0%, #ff9ab6 98.95%, #f03295 100%);
}

.social-links li.insta a {
  background: linear-gradient(#f09376 0%, #f0db79 100%);
}

.social-links li.insta a:hover {
  background: linear-gradient(#f0db79 0%, #f09376 100%);
}

.social-links li {
  margin: 0 13.5px;
}

.copyrights p {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}

.copyrights p a {
  color: #fff;
}

.copyrights p a:hover {
  color: #98201f;
}

.copyrights ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0;
}

.copyrights ul li a {
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: #fff;
}

.copyrights ul li {
  margin: 0 13.5px;
}

.copyrights {
  padding-top: 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.17);
  margin: 39px 37px 0;
}

/* Breadcrumb banner */

.breadcrumb-banner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.breadcrumb-banner h1 {
  margin: 0;
  margin-bottom: 18px;
  color: #fff;
}

.breadcrumb-banner p {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}

/* FAQS CSS */

/* 1 Faq Main*/

.faqs-main .card-header {
  padding: 0;
  border: none;
  background-color: transparent;
}

.faqs-main .card {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 18px;
}

.faqs_title {
  padding: 19px 20px;
  display: flex;
  align-items: center;
}

.faqs_title:after {
  content: "";
  display: block;
  width: 14px;
  height: 13px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.faqs_title[aria-expanded="false"]:after {
  background-image: url(../images/plus.png);
}

.faqs_title[aria-expanded="true"]:after {
  background-image: url(../images/minus.png);
}

.faqs_title .media-body h4 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #000;
  margin: 0;
}

.faqs_title .media-body p {
  font-family: "Lato", sans-serif;
  font-size: 13px;
  line-height: 1;
  color: #000;
  opacity: 0.49;
  margin: 0;
  width: 100%;
}

.faqs-main .card .card-body {
  padding: 0px 20px 20px;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  line-height: 1.4;
  color: #000;
  opacity: 0.49;
}

.faqs-card {
  max-width: 775px;
  margin-bottom: 50px;
}

.text-des {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 8px 0 0;
}

.text-des p {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.faqs-main .media-body {
  overflow: hidden;
  padding-right: 10px;
}

/* Contact Page CSS */

/* 1. Contact Main */

.caption h2 {
  font-weight: 700;
  margin-bottom: 6px;
}

.caption p {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  color: #000;
  margin: 0;
}

.contact-main {
  margin-bottom: 50px;
}

.contact-main .caption {
  margin-bottom: 60px;
}

form label {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: #000;
  display: block;
  margin-bottom: 10px;
}

.contact-main form .form-group {
  margin-bottom: 30px;
}

.contact-main form textarea {
  height: 100%;
  min-height: 221px;
  resize: inherit;
}

.contact-main form .btn {
  min-width: 130px;
  font-size: 23px;
}

.contact-main form .form-control::placeholder {
  opacity: 0.26;
}

.contact-main form .form-control {
  font-family: "Lato", sans-serif;
}

.chat-text p {
  font-size: 20px;
  line-height: 42px;
  color: #000;
}

.chat-text .btn {
  min-width: 267px;
  font-size: 23px;
}

/* News Page CSS */

/* 1. News Main */

.news-wrapper .news-card {
  margin-bottom: 47px;
}

.pagination {
  justify-content: center;
  display: inline-flex;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  padding: 10px 5px;
}

.pagination .page-link {
  margin: 0;
  border: none;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  color: #b23130;
  padding: 2.5px 13px;
  border-left: 1px solid #d4d4d4;
}

.pagination .page-link:hover,
.pagination .page-link:focus {
  background-color: transparent;
  box-shadow: none;
}

.pagination .active .page-link {
  color: #a8a8a8;
  border-left: 1px solid #d4d4d4;
  background-color: transparent;
}

.pagination .page-link:hover img {
  opacity: 0.5;
}

.pagination .page-link:hover {
  color: #a8a8a8;
}

.pagination li:first-child .page-link {
  border-left: none;
}

.invite-friend {
  padding: 44px 0;
}

.invite-friend .form-card {
  max-width: 894px;
  margin: 14px auto 0;
}

.form-card {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
  padding: 25px;
}

.form-card .form-row {
  margin: 0 -9px;
}

.form-card .form-row .form-group {
  margin: 0;
}

.form-card form [class*="col"] {
  padding: 0 9px;
}

.form-card .button-holder {
  padding: 0 9px;
}

.form-card .button-holder .btn {
  min-width: 216px;
}

.live-video {
  padding: 45px 0;
}

.direct-stream {
  padding: 45px 0 90px;
}

.direct-stream .video-box {
  margin-top: 30px;
}

.video-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.video-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.coaching-staking {
  padding: 80px 0;
}

.leadtext {
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #000;
}

.coaching-staking .leadtext {
  max-width: 917px;
  margin: 0 auto 45px;
}

.card-coach h4,
.card-coach p {
  margin: 0;
  line-height: 1;
}

.card-coach h4 p,
.card-coach p {
  color: #000000;
}

.card-coach h4 p {
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
}

.card-coach:hover h4 p {
  color: #98201f;
}

.card-coach p {
  font-size: 14px;
  line-height: 20px;
}

.card-coach {
  text-align: center;
  margin-top: 30px;
}

.card-coach .img-coach {
  border-radius: 10px;
  margin-bottom: 14px;
  transition: all 0.3s;
}

.coching-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.coching-list li {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}

.our-coaches {
  padding: 0px 0 85px;
}

.card-coach:hover .img-coach {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.38);
}

.spin, .rfriend {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 801px;
  margin: 0 auto;
  padding: 0 11px;
}

.spin p {
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #000;
  padding: 0px 15px;
}

.spin-card {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}

.spin-card img {
  width: 100%;
}

.spin {
  padding: 80px 0;
}

.spin-card-wrapper .spin-card {
  margin: 0 -11px;
}

.spin-card-wrapper .spin-card:nth-child(2) {
  margin-top: 69px;
  position: relative;
  z-index: 1;
}

.punto-main {
  padding: 15px 0 45px;
}

.punto-main .card-punto {
  max-width: 323px;
  margin: 0 auto;
}

.request-info h2 {
  margin-bottom: 30px;
  font-weight: 500;
}

.request-info {
  padding: 45px 0 105px;
}

.breadcrumb {
  padding: 0;
  background-color: transparent;
  justify-content: center;
}

.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item.active,
.breadcrumb li a {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "-";
}

.page-title {
  padding: 115px 0 29px;
}

.page-title .caption-title {
  padding: 139px 150px 100px;
}

.page-title .caption-title h1 {
  font-family: "Lato", sans-serif;
  font-size: 60px;
  color: #000;
}

.page-title .caption-title p {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  margin: 0;
}

/************ BLOG PAGE 20-10-2020 *********/

.banner-sec {
  padding: 90px 0 0 0;
}
.blog-sec {
  padding: 50px 0;
}
.blog-sec .title {
  text-align: left;
  max-width: 100%;
  width: 100%;
}
.blog-sec .title h3 {
  color: #000000;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 18px 0;
  padding: 0;
}
.blog-sec .title ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.blog-sec .title ul li {
  color: #929292;
  font-size: 16px;
  font-weight: 400;
  margin-right: 25px;
}
.blog-sec .title ul li:last-child {
  margin-right: 0;
}
.blog-sec .title ul li span {
  color: #000000;
  font-weight: 500;
}

.blog-inner {
  margin: 65px 0 0 0;
}
.blog-inner .text-box p {
  color: #000000;
  font-size: 20px;
  line-height: 37px;
  font-weight: 400;
  margin-bottom: 38px;
}
.blog-inner .social-media {
  display: flex;
  margin: 100px 0 0 0;
  align-items: center;
}
.blog-inner .social-media h4 {
  color: #0c0c0c;
  font-size: 28px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin: 0 27px 0 0;
  padding: 0;
}
.blog-inner .social-media ul {
  display: flex;
  align-items: center;
  margin: 0 -17px;
}
.blog-inner .social-media ul li {
  padding: 0 17px;
}
.blog-inner .social-media ul li a {
  background-color: #b23130;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.related-blog h3 {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 13px;
}
.related-blog-box {
  display: flex;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}
.related-blog-box .image-holder {
  max-width: 93px;
  width: 100%;
}
.related-blog-box .text-box {
  width: 100%;
  flex: 1;
  padding-left: 12px;
  display: flex;
  padding-right: 44px;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}
.related-blog-box .text-box h4 {
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 36px 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}
.related-blog-box .text-box ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.related-blog-box .text-box ul li {
  color: #929292;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
}

/*========================================
	Thank You Page
	=====================================*/
.thankyou-wrapper {
  padding: 120px 0;
}
.thankyou-wrapper a img {
  margin-bottom: 35px;
}
.thankyou-wrapper p {
  color: #3f3f3f;
  font-family: "Montserrat", Sans-serif;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: 1.9px;
  font-weight: 700;
}
.thankyou-wrapper p span {
  text-decoration: underline;
}
.thankyou-wrapper > span.text-themee {
  color: #00b285;
  font-family: "Montserrat", Sans-serif;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: 1.9px;
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
}
.thankyou-wrapper .thnk-btn {
  font-family: "Montserrat", Sans-serif;
  font-size: 38px;
  line-height: 1.1em;
  background-color: #00b285;
  border-radius: 18px 18px 18px 18px;
  padding: 26px 71px 26px 71px;
  margin-top: 40px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
}
.thankyou-wrapper .thnk-btn-2 {
  font-family: "Montserrat", Sans-serif;
  font-size: 38px;
  line-height: 1.1em;
  background-color: #1877f2;
  border-radius: 18px 18px 18px 18px;
  padding: 26px 71px 26px 71px;
  margin-top: 40px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
}
.thankyou-wrapper .thnk-btn-2-wa {
  font-family: "Montserrat", Sans-serif;
  font-size: 38px;
  line-height: 1.1em;
  background-color: #1faa0a;
  border-radius: 18px 18px 18px 18px;
  padding: 26px 71px 26px 71px;
  margin-top: 40px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
}
.thnk-footer {
  background-color: #4c4c4c;
  padding: 150px 0 40px;
}
.thnk-footer p {
  color: #fff;
  font-size: 12px;
}
.thnk-footer img {
  margin: 25px 0;
}

@media (max-width: 768px) {
  .thankyou-wrapper p {
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 1px;
  }
  .thankyou-wrapper > span.text-themee {
    font-size: 28px;
    line-height: 42px;
  }
  .thankyou-wrapper .thnk-btn {
    font-size: 30px;
  }
  .thankyou-wrapper {
    padding: 90px 0;
  }
}

@media (max-width: 480px) {
  .thankyou-wrapper p {
    font-size: 24px;
    line-height: 35px;
  }
  .thankyou-wrapper > span.text-themee {
    font-size: 24px;
    line-height: 35px;
  }
  .thankyou-wrapper .thnk-btn {
    font-size: 18px;
    padding: 16px 30px;
  }
}

.page-title .caption-title h1 {
  margin-left: 31%;
}

@media (max-width: 768px) {
  .page-title .caption-title h1 {
    margin-left: 0;
    text-align: center;
  }
}

.selection {
  margin: 0 auto;
  display: table;
}

.type {
  display: inline-block;
  padding: 10px;
  font-size: 1.5rem;
}

.type:hover,
.type:active {
  color: #b23130;
  cursor: pointer;
}

.type-active {
  color: #b23130;
}


/* Landing pages */
.content-landing {
  margin: 150px auto;
}


.content-landing p {
  margin: 0px;
  padding: 0px;
}

.content-landing .logo-room {
  margin: 60px 0 30px 0;
  max-width: 300px;
}

.content-landing form {
  margin-bottom: 100px;
}

.content-landing .theme-btn {
  margin: 15px auto;
  border: 0px;
}

.page-title.refer-friend .caption-title{
  padding: 139px 0px 100px;
}

.page-title.refer-friend .caption-title h1{
  margin: 0;
}

.refer-friend-reg {
  margin: 60px 0 60px 0;
  height: auto;
  overflow: hidden;
  white-space: pre-line;
}

.refer-friend-reg nav {
  padding: 15px;
}
.refer-friend-reg li {
  margin-bottom: 10px;
  list-style-type: square;
}

.theme-btn.landing {
  cursor: pointer;
  border: none;
}