/*=================Google Fonts=============*/
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

/*==============General Styling=============*/
a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}
input:hover,
input:focus {
  outline: none;
  box-shadow: none;
}
.theme-btn {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 28px;
  background-color: #00b285;
  border-radius: 55px;
  padding: 15px 56px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.theme-btn:hover {
  background-color: #000;
  color: #fff;
}
/*===============Header=============*/
.header-landing {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  z-index: 2;
}
/*=================Hero Section=============*/
.new-hero-sec {
  height: 100vh;
  display: flex;
  align-items: center;
}
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  background-size: cover;
  z-index: -1;
}
.new-hero-text {
  position: relative;
  z-index: 1;
}
.new-hero-text h2 {
  font-size: 74px;
  font-weight: 300;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
}
.new-hero-text h2 strong {
  font-weight: 700;
  display: block;
}
.new-hero-text p {
  font-size: 28px;
  color: #fff;
  max-width: 54%;
  font-family: "Roboto", sans-serif;
}
.new-hero-text a {
  margin-top: 45px;
}
/*======================About Section=============*/
.about-section {
  padding: 90px 0 150px;
}
.about-title {
  position: relative;
  padding: 0 10% 20px;
  margin-bottom: 25px;
}
.about-sec p {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  color: #4c4c4c;
}
.about-title::after {
  content: "";
  height: 4px;
  width: 76px;
  background-color: #bc1d22;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}
.about-title img {
  margin-bottom: 30px;
}
.about-title p {
  font-size: 28px;
  font-weight: 700;
  color: #4c4c4c;
  font-family: "Roboto", sans-serif;
}
.about-txt p strong {
  color: #000;
}
.about-txt {
  padding: 0 6%;
}
/*=====================Benefits Section===============*/
.new-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.new-title::after {
  content: "";
  height: 4px;
  width: 76px;
  background-color: #bc1d22;
  position: absolute;
  bottom: 0;
  left: 0;
}
.new-title h2 {
  font-size: 56px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}
.benefits-list li {
  font-weight: 500;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
}
.benefits-list li img {
  margin-right: 22px;
}
.benefits-list li + li {
  margin-top: 22px;
}
.benefits-txt a {
  margin-top: 50px;
}
/*==================About 2==============*/
.about2-sec {
  padding: 160px 0 90px;
}
.about2-sec .txt p {
  font-size: 22px;
  color: #4c4c4c;
  font-family: "Roboto", sans-serif;
}
.about2-sec .txt p strong {
  color: #000;
}
/*=================Testimonials============*/
.testimonial-sec {
  background-color: #f7f7f7;
  padding: 90px 0;
}
.testimonial-sec h2 {
  font-size: 56px;
  /* margin-bottom: 32px; */
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}
.test-item img {
  margin-bottom: 30px;
}
.test-item p {
  margin-bottom: 20px;
  font-size: 22px;
  color: #4c4c4c;
  font-family: "Roboto", sans-serif;
}
.test-item strong {
  font-size: 26px;
}
.test-item {
  padding: 0 10%;
}
/*====================Contact Section================*/
.contact-sec {
  padding: 180px 0 90px;
}
.contact-content {
  background-image: url("../images/landing/contact-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 200px 70px 90px;
  border-radius: 18px;
}
.contact-content img {
  position: absolute;
  top: -21%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.contact-content h2 {
  font-size: 56px;
  margin-top: -40px;
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}
.contact-form {
  margin-top: 40px;
}
.contact-form input, .contact-form select {
  height: 70px;
  background-color: #fff;
  border-radius: 50px;
  border: none;
  color: #999999;
  font-size: 18px;
  padding: 15px 40px;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.contact-form button {
  border: none;
  width: 100%;
  min-height: 70px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}
/*=================Footer============*/
footer {
  padding: 45px 0 35px;
}
.footer-text {
  padding: 0 5%;
  line-height: 0.75 !important; /* Add !important to override any conflicting styles */
}
.footer-text p {
  color: #cccccc;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.5em;
}
.footer-text img {
  margin: 0px 0 20px;
}

/*==============================================
	Confirmation Page
	===========================================*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.confir-logo-area {
  background-color: #4c4c4c;
  padding: 30px 0;
}
.reg-data-sec {
  background-color: #e2e2e2;
}
.resgister-data-txt {
  padding: 30px 0;
  border-bottom: 3px solid #000;
}
.resgister-data-txt p {
  color: #3f3f3f;
  font-family: "Montserrat", Sans-serif;
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 1.1px;
}
.resgister-data-txt p:last-child strong {
  text-decoration: underline;
}
.reg-form-title {
  padding-bottom: 10px;
  font-size: 36px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  color: #ed3b67;
}
.reg-tag-line {
  font-size: 21px;
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  text-align: center;
  color: #000;
  font-weight: 700;
  margin-bottom: 50px;
}
.reg-tag-line span {
  color: #ed3b67;
}
.reg-data-form {
  margin-top: 80px;
}
.reg-data-form input[type="text"],
.reg-data-form input[type="email"] {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  width: 712px;
  padding: 16px 16px 16px 16px;
  border-radius: 6px 6px 6px 6px;
  background: #fff;
  margin-bottom: 30px;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
}
.reg-data-form img {
  display: block;
  margin: 0 auto 15px;
  max-width: 270px;
}
.reg-data-form .reg-field-txt {
  font-size: 14px;
  color: #000;
  max-width: 50%;
  margin: 0 auto;
}
.reg-form-item {
  margin-bottom: 60px;
}
.reg-room-item {
  margin-bottom: 20px;
}
.reg-room-item span {
  display: block;
  font-size: 14px;
}
.reg-data-form button {
  display: block;
  width: 100%;
  background-color: #23a455;
  border-radius: 22px 22px 22px 22px;
  padding: 14px 30px;
  margin-top: 19px;
  font-family: "Montserrat", Sans-serif;
  font-size: 24px;
  color: #fff;
  border: none;
}
.confir-footer {
  background-color: #e2e2e2;
  padding: 150px 0 40px;
}
.confir-footer p {
  color: #000;
  font-size: 12px;
}
.confir-footer img {
  margin: 25px 0;
}

#checkbox1 {
  position: absolute;
  top: 6px;
  left: 0;
  height: 16px;
  width: 16px;
}
.contact-form label {
  text-align: left;
  padding-left: 18px;
  color: #fff;
}
.contact-form label a {
  font-weight: 700;
  color: #fff;
  text-decoration: underline;
}

.contact-form .form-group {
  margin-bottom: 24px;
}

.embed-container {
	position: relative;
	/* padding-bottom: 56.25%;  16:9 */
	padding-bottom: 33%;
  overflow: hidden;
  max-width: 100%;
  background: transparent;
	height: 0;
}
.embed-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}