.iubenda-privacy-policy {
  max-width: 64rem;
  margin: 0 auto;
  padding: 1.5rem;
  font-family: sans-serif;
}

.iubenda-privacy-policy__title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
}

.iubenda-privacy-policy__subtitle {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.iubenda-privacy-policy__section-title {
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

/* New style for "Titolare del Trattamento dei Dati" */
.iubenda-privacy-policy__titolare-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* New style for the paragraph with increased bottom margin */
.iubenda-privacy-policy__text--large-margin {
  margin-bottom: 3rem;
}

.iubenda-privacy-policy__services-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .iubenda-privacy-policy__services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.iubenda-privacy-policy__service-item {
  display: flex;
}

.iubenda-privacy-policy__service-icon {
  margin-right: 1rem;
  margin-top: 0.25rem;
}

.iubenda-privacy-policy__service-icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.iubenda-privacy-policy__service-title {
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.iubenda-privacy-policy__service-detail {
  margin-bottom: 0.5rem;
}

.iubenda-privacy-policy__service-name {
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.iubenda-privacy-policy__service-description {
  font-size: 0.6875rem;
  color: #4B5563;
}

.iubenda-privacy-policy__contact-info {
  display: flex;
  margin-bottom: 1.5rem;
}

.iubenda-privacy-policy__contact-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.iubenda-privacy-policy__email {
  margin-top: 0.5rem;
}

.iubenda-privacy-policy__last-modified {
  font-size: 0.875rem;
  color: #4B5563;
  margin-top: 2rem;
}

.iubenda-privacy-policy__disclaimer {
  font-size: 0.75rem;
  color: #6B7280;
  margin-top: 0.5rem;
}

.iubenda-privacy-policy__full-policy-link {
  color: #2563EB;
  text-decoration: none;
  font-size: 0.875rem;
  margin-top: 1rem;
  display: inline-block;
}

.iubenda-privacy-policy__full-policy-link:hover {
  text-decoration: underline;
}

.iubenda-privacy-policy__address {
  line-height: 0.5;
}

.iubenda-privacy-policy__address p {
  margin: 0;
}

/* Custom styles for Iubenda cookie banner */
.iubenda-cs-container {
    bottom: 0 !important;
    top: auto !important;
    border-radius: 0 !important;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
}

.iubenda-cs-content {
    border-radius: 0 !important;
}